<template>
    <div class="main-limiter margin-bottom-5">
        <article class="main">
          <h1>Explainable AI<br />How to make your “black box” model understandable.</h1>
          <DoroImage :src="ImageXAI1" max-width="700px"></DoroImage>

          <p style="font-size:0.8em">
            <i>Written by Joaquin Marques, Partner at AI REV.</i>
          </p>

          <p>
            In this article, I’m going to talk about the potential ways we can use Explainable AI to improve the understanding of AI models by humans without formal training or prior experience in Artificial Intelligence methods and techniques. We are going to cover multiple XAI methods, from the simplest like constraints to GANs. After reading this article you will know what these are and be able to determine when, which and how to apply these techniques to understand how algorithms came up with their final answer.
          </p>

          <h2>What is Explainable AI?</h2>
          <p>
            In the past few years, I’ve noticed that <b>AI has become ubiquitous in all areas of our lives</b>. There is hardly any social, cultural, or economic interaction between people that AI has not already touched in some shape or form. What was science-fiction a couple of decades ago for me, has become a reality. <b>AI surrounds us, makes decisions for us, simplifying many aspects of our lives. It can get scary for a lot of people</b>, and I as a Data Scientist with over 30 years of experience still don’t have all of the answers. <b>So, can we afford to give up that much control over our daily lives to a technology hardly anybody understands?</b> Let me try to explain.
          </p>
          <p>
            I personally believe that <b>in realms such as healthcare, finance, and the law, the answer is no</b>. We need to understand the reasons behind decisions made on our behalf by doctors, financial institutions, or judges. And <b>recent laws have been passed in a few countries to protect our right to have those reasons explained clearly in writing</b>. To comply with those laws, the AI algorithms making those decisions need to explain how they arrived at them in the first place. <b>This capability is known as Explainable AI, or XAI for short.</b>
          </p>

          <h2>Why use XAI?</h2>

          <DoroImage :src="ImageXAI2" max-width="740px"></DoroImage>

          <p>
            In healthcare, for example, my peers use XAI to justify/explain medical decisions based on lab exams, CAT scans, MRIs, and X-rays or automated decisions made by medical equipment. The insurance industry uses XAI to justify/explain underwriting decisions on granting policies or payments. The finance industry requires XAI to explain automated decisions on bank loans, stock trades, assets, and risk management.
          </p>

          <p>
            To comply with regulations, courts and governments use XAI to explain legal/civil decisions made by automated software. Human Resources departments use XAI to avoid hiring bias, and manufacturing uses it to justify/explain collective/group manufacturing decisions that affect production and quality. Finally, autonomous vehicles such as Tesla now use XAI to justify driving decisions made by the car during an accident. These are in my opinion some of the examples where XAI is necessary.
          </p>

          <p>
            Many of the algorithms used in AI are, by their very nature, easy to explain, such as decision trees and clustering. But other, more powerful algorithms, such as neural networks and genetic algorithms, are not so easy to explain even for someone with my experience.
          </p>

          <p>
            <b>Explainable AI (XAI) comprises many methods to make most AI algorithms explainable, except genetic algorithms.</b> The latter evolve through many generations into ever-fitter algorithms based on random mutations of their code.
          </p>

          <p>
            <b>XAI-ready versions of many algorithms are readily available as libraries and packages.</b> Consider using them to write any new applications that require XAI. <b>However, there are some AI algorithms and models for which no XAI-ready versions are available. Let's call them "black-box" algorithms and models.</b>
          </p>

          <p>
            <b>They can get really complicated and that is why they need external techniques that test their decision boundaries.</b> These tests offer a glimpse into the black box's inner workings and do not necessarily require modification to the existing algorithms or models. This is where it starts to become problematic for all of us.
          </p>

          <p>
            You can think of this "probing" XAI approach as a form of "psychoanalysis" for black-box algorithms/models. And just like human psychoanalysis, <b>XAI uses many different methods to probe various aspects of the black box’s "psyche."</b> They allow us to "interpret" the "inner workings" of the model or algorithm, and so they are known as interpretability. <br />
            <b>These methods follow three basic approaches:  constraint, perturbation, and generative adversarial networks or GANs.</b>
          </p>


          <h2>The "constraint" approach</h2>

          <p>
            The "constraint" approach keeps a model's behavior within desirable boundaries by applying external constraints. <b>Three XAI methods use this approach: Rationalization, Intelligible Model, and Monotonicity.</b>
          </p>

          <p>
            <b>Rationalization provides a detailed, step-by-step description of how a neural net reaches its decision</b>, similar to a human expert describing his/her train of thought while analyzing a painting, an X-ray, a piece of text, or a photograph. The description constraints, focuses, and continuously reduces the universe of possible alternatives until it reaches the final decision.
          </p>

          <DoroImage :src="ImageXAI4" max-width="740px"></DoroImage>

          <p>
           <b>The Intelligible Model uses an entirely different approach.</b> It reduces the problem to its simplest components, sizing the final tally statistically and using those results as a unique fingerprint or signature that identifies the final decision. For example, identifying the original painter by deconstructing a painting into the individual amounts and types of paints, types of brushes, and strokes used to paint it. Also, analyzing a novel into individual words and their frequencies to identify the author. We finally ensure that the results fall within the boundaries that characterize the author's "style."
          </p>

          <DoroImage :src="ImageXAI3" max-width="740px"></DoroImage>

          <p>
            <b>The idea underlying the third method, Monotonicity, is a bit harder for me to explain.</b> It is close to the concept of "generalization". Let me give you an example and it will hopefully make it easier to understand. Da Vinci's Mona Lisa is as iconic an example of a famous painting as we can imagine. It has been imitated by other famous painters many times in the last 500 years. But each of those paintings, regardless of their particular painter's style, can still be recognized by humans as a version of the original "Mona Lisa." But how can a neural network accomplish such a feat? In other words, to what extent can we alter the "Mona Lisa” while still preserving the "essence" of the concept of the "Mona Lisa"? In music, a monotonic voice holds the same central "pitch" throughout a melody. In our example, all the paintings are identified as "Mona Lisas" despite their different styles.
          </p>

          <DoroImage :src="ImageXAI5" max-width="740px" square></DoroImage>



          <h2>“Perturbation”</h2>

          <p>
            This is where our second approach comes along, known as "perturbation," it monitors a model's outputs while probing its decision-making boundaries, thus allowing it to figure out how perturbations to the model's inputs affect its decisions.
          </p>

          <p>
            <b>There are two XAI methods based on "perturbation," known as "Axiomatic Attribution" and "Counterfactual Method."</b>
          </p>

          <p>
            <b>Axiomatic Attribution answers the question:</b> when a neural network starts perceiving an image or a piece of text, at what point does an object or a concept first become recognizable? In other words, what is the minimum amount of information required to "perturb" a neural net for it to recognize a version of the Mona Lisa as such?
          </p>

          <DoroImage :src="ImageXAI6" max-width="740px" square></DoroImage>

          <p>
            <b>The Counterfactual Method, as the name implies,</b> is based on the notion of "counterfactuals." Counterfactuals are any inputs that are contrary to the facts, that is, not part of reality. They are central to posing "what if" questions: if the past had been different in some way (counterfactual), what would the present be like now? If Da Vinci had painted the Mona Lisa without her mysterious smile, would it still be the Mona Lisa? To what extent can we perturb the original concept before it becomes unrecognizable by me, you, an expert or a neural network?
          </p>

          <DoroImage :src="ImageXAI7" max-width="740px" square></DoroImage>



          <h2>GANs</h2>

          <p>
            <b>The "Generative Adversarial Networks" or GANs approach is the most complex of all.</b> It uses two deep neural networks, one acting as a "student" and the other as a "teacher." They continuously test each other's decisions and knowledge gaps by alternating roles while constantly improving their questions and answers until both networks become such experts that they find nothing to teach to or learn from one another. Just like some of us studied for SATs with our friends, by quizzing each other. The method used is known as “Feature Visualization.”
          </p>

          <p>
            Both neural networks continuously keep track of what they learn from each other and how they learn it, thus providing insight into the expert's black-box way of thinking.
          </p>

          <p>
            Suppose we trained a neural net to recognize every known van Gogh painting as a "genuine" van Gogh, and every other painting, including high-quality imitations, as "fake" van Goghs. Let's call this net the "Critic." The Critic is also able to explain how to paint a genuine van Gogh stroke by stroke. And suppose we partially train another neural net to both generate and recognize actual van Goghs, but not as accurately as the Critic. Let's call this second net the "Apprentice."
          </p>

          <p>
            Next, we have the Apprentice generate a van Gogh while the Critic is watching. As the Apprentice makes mistakes, the Critic immediately notices and warns the Apprentice about them in detail. The Apprentice self-corrects and keeps tabs on its corrections. They play adversarial roles. Eventually, the Apprentice becomes as good as if not better than the Critic at generating perfect van Goghs.
          </p>

          <DoroImage :src="ImageXAI8" max-width="740px"></DoroImage>

          <p>
            Finally, after the Critic stops correcting the Apprentice, we have them switch roles and start the cycle again. As the nets train each other, they become better and better master van Gogh painters! And we have detailed instructions generated by both nets on how to both create and identify perfect van Gogh paintings.
          </p>


          <h2>New Legislation</h2>

          <p>
            <b>Why do we need so many different approaches and methods to do XAI?</b> Simply because, like human psychoanalysis, <b>one approach/method is not enough to capture all the required nuances of what is going on inside a deep learning model.</b> If I were to describe your personality using results from one psychoanalysis test, it wouldn't be a good depiction of who you are. There are, in fact, many other emerging methods we have not even addressed.
          </p>

          <p>
            <b>We briefly mentioned that there are already several laws around the world that protect people's "right to an explanation."</b>  The <b>"Equal Credit Opportunity Act"</b> in the US, for example, compels creditors to provide applicants with clear reasons why their credit application was denied beyond simply quoting the creditors’ internal policies.
          </p>

          <p>
            The European Union currently enforces their <b>"General Data Protection Regulation,"</b> which states that a data subject has the right to have the reasons behind legal or financial decisions reached by automated systems impacting him/her clearly explained.
          </p>

          <p>
            Lastly, France's <b>"Digital Republic Act"</b> regulates legal and administrative decisions made by public sector organizations about an individual, where the decision-making process need not be fully automated, giving the individual a right to a clear explanation of the details behind the entire process.
          </p>


          <h2>Summary</h2>

          <p>
            <b>XAI is becoming essential in many areas. In the future, all AI systems will be able to explain the reasoning behind their decisions.</b> In some cases, their chain of reasoning may be so long that it will be well beyond any human's capacity to understand, but still, it can be summarized and allow us to understand how these mechanisms work. <b>I think that the industry is advancing in the right direction, however, there is still a lot of work to be done.</b>
          </p>
        </article>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import URL from '@/components/URL';

  import ImageXAI1 from '@/assets/articles/explainable-ai/XAI1.jpg';
  import ImageXAI2 from '@/assets/articles/explainable-ai/XAI2.jpg';
  import ImageXAI3 from '@/assets/articles/explainable-ai/XAI3.jpg';
  import ImageXAI4 from '@/assets/articles/explainable-ai/XAI4.jpg';
  import ImageXAI5 from '@/assets/articles/explainable-ai/XAI5.jpg';
  import ImageXAI6 from '@/assets/articles/explainable-ai/XAI6.jpg';
  import ImageXAI7 from '@/assets/articles/explainable-ai/XAI7.jpg';
  import ImageXAI8 from '@/assets/articles/explainable-ai/XAI8.jpg';
  import ImageTitle from '@/assets/articles/explainable-ai/title.jpg';

	@Component({
        components: {
            DoroImage,
            URL,
        },
        metaInfo() {
            return {
              title: 'Explainable AI - how to make your “black box” model understandable',
              meta: [
                {
                  name: 'title',
                  content: "Explainable AI - how to make your “black box” model understandable"
                },
                {
                  name: 'description',
                  content: "Dive into this riveting exploration of Explainable AI (XAI), as we decode AI decision-making, transforming it from a mystery to a comprehensible tool, especially in vital sectors like healthcare, finance, and law."
                },
                {
                  property: 'og:title',
                  content: "Explainable AI - how to make your “black box” model understandable",
                },
                {
                  property: 'og:image',
                  content: "https://airev.us" + ImageTitle,
                },
                {
                  property: 'og:description',
                  content: "Dive into this riveting exploration of Explainable AI (XAI), as we decode AI decision-making, transforming it from a mystery to a comprehensible tool, especially in vital sectors like healthcare, finance, and law.",
                },
                {
                  property: 'og:url',
                  content: "https://airev.us/explainable-ai",
                },
                {
                  property: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  property: 'twitter:url',
                  content: "https://airev.us/explainable-ai",
                },
                {
                  property: 'twitter:title',
                  content: "Explainable AI - how to make your “black box” model understandable",
                },
                {
                  property: 'twitter:description',
                  content: "Dive into this riveting exploration of Explainable AI (XAI), as we decode AI decision-making, transforming it from a mystery to a comprehensible tool, especially in vital sectors like healthcare, finance, and law.",
                },
                {
                  property: 'twitter:image',
                  content: "https://airev.us" + ImageTitle,
                }
              ],
              script: [{
                type: 'application/ld+json',
                json: {
                  "@context": "https://schema.org",
                  "@type": "Article",
                  "name": "Explainable AI - how to make your “black box” model understandable",
                  "description": "Dive into this riveting exploration of Explainable AI (XAI), as we decode AI decision-making, transforming it from a mystery to a comprehensible tool, especially in vital sectors like healthcare, finance, and law.",
                  "image": "https://airev.us" + ImageTitle,
                }
              }]
            }
        }
    })
    export default class ExplainerDashboard extends Vue {
    get ImageXAI1() { return ImageXAI1; }
    get ImageXAI2() { return ImageXAI2; }
    get ImageXAI3() { return ImageXAI3; }
    get ImageXAI4() { return ImageXAI4; }
    get ImageXAI5() { return ImageXAI5; }
    get ImageXAI6() { return ImageXAI6; }
    get ImageXAI7() { return ImageXAI7; }
    get ImageXAI8() { return ImageXAI8; }

    }
</script>
